import React from "react"
import { Link, graphql } from "gatsby"
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
// import { css } from "@emotion/core"
// import { rhythm } from "../utils/typography"
import Layout from "../components/layout"

export default ({ data }) => {
  if (!isAuthenticated()) {
    const user = false;
  } else {
    const user = getProfile()
  }
  return (
    <Layout>
      <div>
        <h1>a few words</h1>
        <div></div>
        <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <Link to={node.fields.slug}>
              <h3>
                {node.frontmatter.title} <span>— {node.frontmatter.date}</span>
              </h3>
              <p>{node.frontmatter.description}</p>
            </Link>
          </div>
        ))}
        <h4>
          <Link to="/posts/">All Posts</Link>
          
        </h4>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(fromNow: true)
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
